<template>
  <div class="verification">
    <!-- Show loading state while the verification request is being processed -->
    <label for="verification" class="title">
      <i class="fa-solid fa-user-check fa-lg"></i>
      User Verification
    </label>
    <div v-if="loading" class="status">
      <p>Verifying your email...</p>
    </div>

    <!-- Success message with a countdown and redirect options -->
    <div v-if="!loading && success" class="status success">
      <i class="fa fa-check-circle fa-2xl"></i>
      <p class="achant">Your account has been verified successfully!</p>
      <div class="redirect">
        <p>Redirecting in {{ countdown }} seconds...</p>
        <p>
          If not redirected, you can go to

          <router-link class="link" v-if="!isAuthenticated" to="/log-in">
            log-in
          </router-link>

          <router-link
            class="link"
            v-if="isAuthenticated"
            to="/corporate-registry"
          >
            your-account
          </router-link>

          or
          <router-link class="link" to="/">return home</router-link>.
        </p>
      </div>
    </div>

    <!-- Error message if verification fails -->
    <div v-if="!loading && error" class="status error">
      <i class="fa fa-exclamation-triangle fa-2xl"></i>
      <p class="achant">Verification failed: {{ errorMessage }}</p>
      <div class="redirect">
        <p>
          Try again later,

          <router-link class="link" v-if="!isAuthenticated" to="/log-in">
            log-in
          </router-link>

          <router-link class="link" v-if="isAuthenticated" to="/your-account">
            your-account
          </router-link>

          or
          <router-link class="link" to="/">return home</router-link>.
        </p>
        <p>
          If the problem persists, please, contact with our
          <a
            class="link"
            target="_blank"
            href="mailto:biglodevelop@gmail.com?subject=User Verification Error"
            >support</a
          >
        </p>
      </div>
    </div>

    <!-- Notification for additional user feedback -->
    <NotificationCustom
      v-if="notification.message"
      :type="notification.type"
      :message="notification.message"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import NotificationCustom from "@/components/common/NotificationCustom.vue";

export default {
  name: "UserVerification",
  components: { NotificationCustom },
  data() {
    return {
      loading: true,
      success: false,
      error: false,
      errorMessage: "",
      countdown: 10,
      notification: {
        message: "",
        type: "",
      },
      intervalId: null,
    };
  },
  computed: {
    // Computed property to determine the redirect path based on authentication status
    redirectPath() {
      return this.isAuthenticated ? "/corporate-registry" : "/log-in";
    },
    ...mapGetters(["isAuthenticated"]),
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    // Method to verify the email token
    async verifyEmail() {
      const token = this.$route.query.token; // Extract token from URL
      if (!token) {
        this.errorMessage = "Invalid verification link.";
        this.error = true;
        this.loading = false;
        return;
      }

      try {
        // Send GET request to the backend with token as query parameter
        const response = await axios.get(
          `/api/users/verify-email?token=${token}`
        );

        // Handle success case
        if (response.data.message === "Email verified successfully") {
          this.success = true;
          this.showNotification("success", "Email verified successfully!");
          this.startCountdown();
        } else {
          throw new Error(response.data.error || "Verification failed.");
        }
      } catch (err) {
        // Handle error cases
        this.errorMessage =
          err.response?.data?.error || err.message || "Verification failed.";
        this.error = true;
        this.showNotification("error", this.errorMessage);
      } finally {
        this.loading = false;
      }
    },

    // Method to start the countdown for redirection
    startCountdown() {
      this.intervalId = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.intervalId);
          this.$router.push(this.redirectPath);
        }
      }, 1000);
    },

    // Method to show a custom notification
    showNotification(type, message) {
      this.notification = { type, message };
    },
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 10px auto 26px auto;
  text-align: center;
  color: #004aad;
}
.verification {
  background-color: white;
  padding: 20px;
  margin: 10px auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.status {
  margin: 20px 0;
}

.status.success {
  color: #00d112;
}

.status.error {
  color: #e60000;
}

.status .achant {
  margin: 20px 0 10px;
}

.status .redirect {
  color: black;
}

.status .redirect .link {
  font-weight: 500;
  color: #004aad;
}
.status .redirect .link:hover {
  text-decoration: underline;
}
</style>
