<template>
  <Header />
  <div class="container100svh-352px">
    <UserLogin />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/common/Header.vue";
import UserLogin from "@/components/SignIn/UserLogin.vue";
import Footer from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogInPage",
  metaInfo: {
    title: "Biglo | Sign In",
  },
  components: {
    Header,
    UserLogin,
    Footer,
  },
});
</script>

<style scoped></style>
