<template>
  <div class="home">
    <Header />
    <HomeMain />
    <SectionArticles />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import HomeMain from "@/components/Home/Main.vue";
import SectionArticles from "@/components/Home/SectionArticles.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    HomeMain,
    SectionArticles,
    Footer,
  },
};
</script>

<style></style>
