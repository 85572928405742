<template>
  <div class="p-6 bg-white rounded-lg shadow-lg space-y-6">
    <h1 class="text-2xl font-bold text-darkblue flex items-center space-x-2">
      <i class="fa-solid fa-user fa-lg"></i>
      <span>User Details</span>
    </h1>

    <form @submit.prevent="updateUser" class="space-y-6">
      <!-- Logo Section -->
      <div>
        <label class="block text-darkblue font-semibold mb-1">Logo</label>
        <div class="flex items-center space-x-4">
          <!-- Display ImageView only if logo is defined -->
          <ImageView
            v-if="user.logo"
            :image-id="user.logo"
            class="rounded-md max-w-48 max-h-48"
          />
          <DropZone v-if="!user.logo" v-model="logo" class="rounded-md" />
        </div>
      </div>

      <!-- Form Fields for User Data -->
      <div class="space-y-4">
        <div>
          <label for="user-name" class="block text-darkblue font-semibold mb-1"
            >Name</label
          >
          <input
            type="text"
            name="user-name"
            v-model="formData.name"
            id="user-name"
            placeholder="Name"
            class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
          />
        </div>

        <div>
          <label for="user-email" class="block text-darkblue font-semibold mb-1"
            >Email</label
          >
          <input
            type="email"
            name="user-email"
            id="user-email"
            v-model="formData.email"
            placeholder="Email"
            class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
          />
        </div>
        <span v-if="!isEmailValid()" class="text-redError text-sm">{{
          emailErrorMessage
        }}</span>
      </div>

      <!-- Telephone Input -->
      <div>
        <label
          for="user-telephone"
          class="block text-darkblue font-semibold mb-1"
          >Telephone</label
        >
        <input
          type="tel"
          ref="telephone"
          name="user-telephone"
          id="user-telephone"
          placeholder="Telephone"
          v-model="formData.telephone"
          @input="restrictToDigits"
          class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
        />
        <span
          v-if="!isPhoneValid() && formData.telephone"
          class="text-redError text-sm"
          >{{ telephoneErrorMessage }}</span
        >
      </div>

      <!-- Additional Fields -->
      <div class="space-y-4">
        <div>
          <label
            for="user-website"
            class="block text-darkblue font-semibold mb-1"
            >Website</label
          >
          <input
            type="url"
            v-model="formData.website"
            id="user-website"
            placeholder="Website"
            class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
          />
        </div>
        <div>
          <label
            for="user-country"
            class="block text-darkblue font-semibold mb-1"
            >Country</label
          >
          <input
            type="text"
            v-model="formData.country"
            id="user-country"
            placeholder="Country"
            class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
          />
        </div>
        <div>
          <label for="user-nif" class="block text-darkblue font-semibold mb-1"
            >NIF</label
          >
          <input
            type="text"
            v-model="formData.nif"
            id="user-nif"
            placeholder="NIF"
            class="w-full px-4 py-2 border border-grey rounded-md focus:outline-none focus:border-darkblue transition duration-200"
          />
        </div>
      </div>

      <!-- Password Management Section -->
      <div class="space-y-6">
        <div>
          <label
            for="user-password"
            class="block text-darkblue font-semibold mb-1"
            >New Password</label
          >
          <div
            class="flex items-center border border-grey rounded-md px-4 py-2 focus-within:border-darkblue transition duration-200"
          >
            <input
              :type="newPasswordFieldType"
              autocomplete="off"
              name="user-password"
              id="user-password"
              v-model="formData.newPassword"
              class="flex-1 outline-none"
            />
            <button type="button" @click="togglePassword('new')" class="ml-2">
              <i :class="newPasswordIcon"></i>
            </button>
          </div>
          <span
            v-if="!isPasswordComplex() && formData.newPassword.length > 0"
            class="text-redError text-sm"
            >{{ passwordErrorMessage }}</span
          >
        </div>

        <div v-if="!isFormInvalid() && !noChangesMade">
          <label
            for="current-password"
            class="block text-darkblue font-semibold mb-1"
            >Current Password</label
          >
          <div
            class="flex items-center border border-grey rounded-md px-4 py-2 focus-within:border-darkblue transition duration-200"
          >
            <input
              :type="currentPasswordFieldType"
              name="current-password"
              id="current-password"
              v-model="formData.currentPassword"
              required
              class="flex-1 outline-none"
            />
            <button
              type="button"
              @click="togglePassword('current')"
              class="ml-2"
            >
              <i :class="currentPasswordIcon"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Save Button -->
      <button
        type="submit"
        :disabled="isFormInvalid() || !formData.currentPassword"
        class="w-full bg-darkblue text-white py-3 mt-4 rounded-md font-semibold shadow-md hover:bg-darkblue-alpha transition duration-200 disabled:opacity-50"
      >
        Save Changes
      </button>
    </form>

    <!-- Verification & Notifications -->
    <RemindEmailVerification
      v-if="!user.isVerified"
      class="block mt-6 mx-auto text-sm font-semibold"
    />
    <NotificationCustom
      v-for="(notification, index) in notifications"
      :key="index"
      :type="notification.type"
      :message="notification.message"
      :duration="notification.duration"
      class="mt-4"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import RemindEmailVerification from "@/components/SignIn/RemindEmailVerification.vue";
import DropZone from "../common/Dropzone.vue";
import ImageView from "@/components/common/Image.vue";

export default {
  name: "UserDetails",
  components: {
    NotificationCustom,
    RemindEmailVerification,
    ImageView,
    DropZone,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        telephone: "",
        website: "",
        country: "",
        nif: "",
        newPassword: "",
        currentPassword: "",
        logo: null,
      },
      newPasswordFieldType: "password",
      currentPasswordFieldType: "password",
      newPasswordIcon: "fa-solid fa-eye-slash",
      currentPasswordIcon: "fa-solid fa-eye-slash",
      emailErrorMessage: "",
      telephoneErrorMessage: "",
      passwordErrorMessage: "",
      notifications: [],
      fallbackImage: require("@/assets/images/user-fallback.jpg"),
      isDataLoaded: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    dataText() {
      if (this.noChangesMade()) {
        return "You haven't made any change";
      } else if (this.isFieldEmpty()) {
        return "You cannot leave formerly filled fields empty";
      } else {
        return "Check every field";
      }
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser._id && !this.isDataLoaded) {
          this.isDataLoaded = true;
          this.formData = {
            name: newUser.name || "",
            email: newUser.email || "",
            telephone: newUser.telephone || "",
            website: newUser.website || "",
            country: newUser.country || "",
            nif: newUser.nif || "",
            logo: newUser.logo || this.fallbackImage,
          };
          this.fetchUserLogo();
          this.fetchUserData();
        }
      },
    },
  },
  methods: {
    ...mapActions(["fetchUser", "updateUserAction"]),
    async fetchUserData() {
      try {
        if (this.user?._id) {
          await this.fetchUser(this.user._id);
        } else {
          console.error("User ID is undefined");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchUserLogo() {
      try {
        console.log("Fetching user logo with ID:", this.user.logo); // Debugging line
        if (this.user.logo) {
          // Fetch the image using the backend API route
          this.logoUrl = `/api/images/${this.user.logo}`;
        } else {
          console.warn("Logo ID is undefined. Using fallback image.");
          this.logoUrl = this.fallbackImage; // Fallback if undefined
        }
      } catch (error) {
        console.error("Error fetching profile logo:", error);
        this.logoUrl = this.fallbackImage; // Fallback if error occurs
      }
    },
    async updateUser() {
      if (this.isFormInvalid()) return;
      try {
        const updatedUser = {
          ...this.user,
          ...this.formData,
          password: this.formData.newPassword,
        };
        await this.updateUserAction({
          user: updatedUser,
          currentPassword: this.formData.currentPassword,
        });
        this.formData.newPassword = "";
        this.formData.currentPassword = "";
        this.addNotification("User details updated successfully!", "success");
      } catch (error) {
        this.addNotification("Error submitting form.", "error");
      }
    },
    togglePassword(version) {
      if (version === "new") {
        this.newPasswordFieldType =
          this.newPasswordFieldType === "password" ? "text" : "password";
        this.newPasswordIcon =
          this.newPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      } else if (version === "current") {
        this.currentPasswordFieldType =
          this.currentPasswordFieldType === "password" ? "text" : "password";
        this.currentPasswordIcon =
          this.currentPasswordFieldType === "password"
            ? "fa-solid fa-eye-slash"
            : "fa-solid fa-eye";
      }
    },
    handleLogoChange(event) {
      const file = event.target.files[0];
      if (file) this.uploadLogo(file);
    },
    removeLogo() {
      this.formData.logo = null;
    },
    isFormInvalid() {
      return (
        !this.isEmailValid() ||
        !this.isPhoneValid() ||
        !this.isPasswordComplex() ||
        this.isFieldEmpty()
      );
    },
    isFieldEmpty() {
      return (
        this.formData.name === "" ||
        this.formData.email === "" ||
        this.formData.telephone === "" ||
        this.formData.website === "" ||
        this.formData.country === "" ||
        this.formData.nif === ""
      );
    },
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.formData.email.includes("+")) {
        this.emailErrorMessage = 'This field cannot contain "+"';
        return false;
      } else if (!emailRegex.test(this.formData.email)) {
        this.emailErrorMessage = "Invalid email format";
        return false;
      } else {
        this.emailErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    isPasswordComplex() {
      if (!this.formData.newPassword) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const password = this.formData.newPassword;
      if (password.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(password)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    isPhoneValid() {
      const phoneRegex = /^[0-9]{9,15}$/;
      if (!phoneRegex.test(this.formData.telephone)) {
        this.telephoneErrorMessage =
          "Phone number must be between 9 and 15 digits.";
        return false;
      } else {
        this.telephoneErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    restrictToDigits() {
      this.formData.telephone = this.formData.telephone.replace(/\D/g, "");
    },
    addNotification(message, type) {
      const newNotification = {
        type,
        message,
        duration: 5000,
      };
      this.notifications.push(newNotification);
      setTimeout(
        () =>
          (this.notifications = this.notifications.filter(
            (n) => n !== newNotification
          )),
        newNotification.duration
      );
    },
    noChangesMade() {
      return Object.keys(this.formData).every(
        (key) => this.formData[key] === (this.user[key] || "")
      );
    },
  },
  mounted() {
    this.fetchUserData();

    this.telephoneInput = intlTelInput(this.$refs.telephone, {
      initialCountry: "auto",
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback("us"));
      },
    });
  },
};
</script>

<style scoped></style>
