<template>
  <nav
    class="flex min-md:justify-center md:overflow-x-auto md:whitespace-nowrap md:scrollbar-hidden my-4 md:w-11/12 mx-auto scroll-snap-type-x-mandatory"
  >
    <!-- Iterate over steps and render StepNavButton with a separator between each -->
    <StepsNavButtons
      v-for="(step, index) in steps"
      :key="step.name"
      :stepName="step.name"
      :iconClass="step.icon"
      :isCurrent="currentStep === step.name"
      :isActive="arePreviousStepsFilled(step.name)"
      :separatorClass="{
        'separator-blue':
          arePreviousStepsFilled(step.name) &&
          arePreviousStepsFilled(steps[index - 1]?.name),
      }"
      :showSeparator="index > 0"
      @navigate="navigateStep"
      class="flex-shrink-0 scroll-snap-align-start"
    />
  </nav>

  <!-- Input Nav Buttons & Main -->
  <div class="wrapper">
    <!-- Inputs Nav Buttons -->
    <div class="inputs-nav-buttons ml-[5dvw]">
      <!-- 3rd Step: Location Nav Buttons -->
      <div
        class="location-input-nav-buttons inputs-nav-buttons"
        v-if="currentStep === 'location'"
      >
        <!-- Country Nav Button -->
        <button
          class="input-nav-button"
          data-text="Country"
          @click="navigateInput('location', 'country')"
          :class="{
            'current-button': locationCurrentInput === 'country',
          }"
        >
          <i
            class="fa-solid fa-flag"
            :class="{
              'fa-xl': locationCurrentInput === 'country',
              'fa-lg': locationCurrentInput !== 'country',
            }"
          ></i>
        </button>

        <!-- Region Nav Button -->
        <div
          class="input-separator sep-region"
          :class="{
            'separator-blue': arePreviousInputsFilled('location', 'region'),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="Region"
          :disabled="!arePreviousInputsFilled('location', 'region')"
          @click="navigateInput('location', 'region')"
          :class="{
            'current-button': locationCurrentInput === 'region',
          }"
        >
          <i
            class="fa-solid fa-location-crosshairs"
            :class="{
              'fa-xl': locationCurrentInput === 'region',
              'fa-lg': locationCurrentInput !== 'region',
            }"
          ></i>
        </button>

        <!-- City Nav Button -->
        <div
          class="input-separator sep-city"
          :class="{
            'separator-blue': arePreviousInputsFilled('location', 'city'),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="City"
          :disabled="!arePreviousInputsFilled('location', 'city')"
          @click="navigateInput('location', 'city')"
          :class="{
            'current-button': locationCurrentInput === 'city',
          }"
        >
          <i
            class="fa-solid fa-city"
            :class="{
              'fa-xl': locationCurrentInput === 'city',
              'fa-lg': locationCurrentInput !== 'city',
            }"
          ></i>
        </button>

        <!-- ZIP Nav Button -->
        <div
          class="input-separator sep-zip"
          :class="{
            'separator-blue': arePreviousInputsFilled('location', 'zip'),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="ZIP"
          :disabled="!arePreviousInputsFilled('location', 'zip')"
          @click="navigateInput('location', 'zip')"
          :class="{
            'current-button': locationCurrentInput === 'zip',
          }"
        >
          <i
            class="fa-solid fa-envelope"
            :class="{
              'fa-xl': locationCurrentInput === 'zip',
              'fa-lg': locationCurrentInput !== 'zip',
            }"
          ></i>
        </button>

        <!-- Address Nav Button -->
        <div
          class="input-separator sep-address"
          :class="{
            'separator-blue': arePreviousInputsFilled('location', 'address'),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="Address"
          :disabled="!arePreviousInputsFilled('location', 'address')"
          @click="navigateInput('location', 'address')"
          :class="{
            'current-button': locationCurrentInput === 'address',
          }"
        >
          <i
            class="fa-solid fa-house"
            :class="{
              'fa-xl': locationCurrentInput === 'address',
              'fa-lg': locationCurrentInput !== 'address',
            }"
          ></i>
        </button>
      </div>

      <!-- 4th Step: Dimensions Nav Buttons -->
      <div
        class="dimensions-input-nav-buttons inputs-nav-buttons"
        v-if="currentStep === 'dimensions'"
      >
        <!-- Surface Nav Button -->
        <button
          class="input-nav-button"
          data-text="Surface"
          @click="navigateInput('dimensions', 'surface')"
          :class="{
            'current-button': dimensionsCurrentInput === 'surface',
          }"
        >
          <i
            class="fa-solid fa-maximize"
            :class="{
              'fa-xl': dimensionsCurrentInput === 'surface',
              'fa-lg': dimensionsCurrentInput !== 'surface',
            }"
          ></i>
        </button>

        <!-- Height Nav Button -->
        <div
          class="input-separator sep-height"
          :class="{
            'separator-blue': arePreviousInputsFilled('dimensions', 'height'),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="Height"
          :disabled="!arePreviousInputsFilled('dimensions', 'height')"
          @click="navigateInput('dimensions', 'height')"
          :class="{
            'current-button': dimensionsCurrentInput === 'height',
          }"
        >
          <i
            class="fa-solid fa-arrows-up-down"
            :class="{
              'fa-xl': dimensionsCurrentInput === 'height',
              'fa-lg': dimensionsCurrentInput !== 'height',
            }"
          ></i>
        </button>

        <!-- Pallet Positions Nav Button -->
        <div
          class="input-separator sep-palletPositions"
          :class="{
            'separator-blue': arePreviousInputsFilled(
              'dimensions',
              'palletPositions'
            ),
          }"
        ></div>
        <button
          class="input-nav-button"
          data-text="Pallet Positions"
          :disabled="!arePreviousInputsFilled('dimensions', 'palletPositions')"
          @click="navigateInput('dimensions', 'palletPositions')"
          :class="{
            'current-button': dimensionsCurrentInput === 'palletPositions',
          }"
        >
          <i
            class="fa-solid fa-pallet"
            :class="{
              'fa-xl': dimensionsCurrentInput === 'palletPositions',
              'fa-lg': dimensionsCurrentInput !== 'palletPositions',
            }"
          ></i>
        </button>
      </div>

      <!-- 5th Step: Equipment Nav Buttons -->
      <div
        class="equipment-input-nav-buttons inputs-nav-buttons"
        v-if="currentStep === 'equipment'"
      >
        <!-- Switches Nav Button -->
        <button
          class="input-nav-button"
          data-text="Checkboxes"
          @click="navigateInput('equipment', 'switches')"
          :class="{
            'current-button': equipmentCurrentInput === 'switches',
          }"
        >
          <i
            class="fa-solid fa-square-check"
            :class="{
              'fa-xl': equipmentCurrentInput === 'switches',
              'fa-lg': equipmentCurrentInput !== 'switches',
            }"
          ></i>
        </button>
      </div>

      <!-- 6th Step: Storage Nav Buttons -->
      <div
        class="storage-input-nav-buttons inputs-nav-buttons"
        v-if="currentStep === 'storage'"
      >
        <!-- Block Storage Nav Button -->
        <button
          class="input-nav-button"
          data-text="Block Storage"
          @click="navigateInput('storage', 'blockStorage')"
          :class="{
            'current-button': storageCurrentInput === 'blockStorage',
          }"
        >
          <i
            class="fa-solid fa-boxes-stacked"
            :class="{
              'fa-xl': storageCurrentInput === 'blockStorage',
              'fa-lg': storageCurrentInput !== 'blockStorage',
            }"
          ></i>
        </button>

        <!-- Direct Storage Nav Button -->
        <div class="input-separator sep-height separator-blue"></div>
        <button
          class="input-nav-button"
          data-text="Direct Storage"
          @click="navigateInput('storage', 'directStorage')"
          :class="{
            'current-button': storageCurrentInput === 'directStorage',
          }"
        >
          <i
            class="fa-solid fa-boxes-packing"
            :class="{
              'fa-xl': storageCurrentInput === 'directStorage',
              'fa-lg': storageCurrentInput !== 'directStorage',
            }"
          ></i>
        </button>

        <!-- Automated Storage Nav Button -->
        <div class="input-separator sep-height separator-blue"></div>
        <button
          class="input-nav-button"
          data-text="Automated Storage"
          @click="navigateInput('storage', 'automatedStorage')"
          :class="{
            'current-button': storageCurrentInput === 'automatedStorage',
          }"
        >
          <i
            class="fa-solid fa-robot"
            :class="{
              'fa-xl': storageCurrentInput === 'automatedStorage',
              'fa-lg': storageCurrentInput !== 'automatedStorage',
            }"
          ></i>
        </button>
      </div>

      <!-- 9th Step: Multimedia Nav Buttons -->
      <div
        class="multimedia-input-nav-buttons inputs-nav-buttons"
        v-if="currentStep === 'multimedia'"
      >
        <!-- Images Nav Button -->
        <button
          class="input-nav-button"
          data-text="Images"
          @click="navigateInput('multimedia', 'images')"
          :class="{
            'current-button': multimediaCurrentInput === 'images',
          }"
        >
          <i
            class="fa-solid fa-image"
            :class="{
              'fa-xl': multimediaCurrentInput === 'images',
              'fa-lg': multimediaCurrentInput !== 'images',
            }"
          ></i>
        </button>

        <!-- Videos Nav Button -->
        <div class="input-separator sep-height separator-blue"></div>
        <button
          class="input-nav-button"
          data-text="Videos"
          @click="navigateInput('multimedia', 'videos')"
          :class="{
            'current-button': multimediaCurrentInput === 'videos',
          }"
        >
          <i
            class="fa-solid fa-film"
            :class="{
              'fa-xl': multimediaCurrentInput === 'videos',
              'fa-lg': multimediaCurrentInput !== 'videos',
            }"
          ></i>
        </button>

        <!-- Blueprints Nav Button -->
        <div class="input-separator sep-height separator-blue"></div>
        <button
          class="input-nav-button"
          data-text="Blueprints"
          @click="navigateInput('multimedia', 'blueprints')"
          :class="{
            'current-button': multimediaCurrentInput === 'blueprints',
          }"
        >
          <i
            class="fa-solid fa-pen-ruler"
            :class="{
              'fa-xl': multimediaCurrentInput === 'blueprints',
              'fa-lg': multimediaCurrentInput !== 'blueprints',
            }"
          ></i>
        </button>
      </div>
    </div>

    <!-- Register Space Form -->
    <form class="register-space-form" @submit.prevent="handleTypeStep">
      <!-- 1st Step: Type -->
      <div class="step-container type-step" v-if="currentStep === 'type'">
        <span class="step-form-title">
          <i class="fa-solid fa-clipboard-question"></i>
          1st step: Type</span
        >

        <!-- Conventional Input -->
        <div class="input-container conventional-input">
          <input
            type="radio"
            id="conventional"
            name="type"
            value="conventional"
            v-model="typeData.type"
          />
          <label for="conventional">Conventional Warehouse</label>
        </div>

        <!-- High Intensity Input -->
        <div class="input-container high-intensity-input">
          <input
            type="radio"
            id="highIntensity"
            name="type"
            value="highIntensity"
            v-model="typeData.type"
          />
          <label for="highIntensity">High Intensity Warehouse</label>
        </div>

        <!-- Automated Input -->
        <div class="input-container automated-input">
          <input
            type="radio"
            id="automated"
            name="type"
            value="automated"
            v-model="typeData.type"
          />
          <label for="automated">Automated Warehouse</label>
        </div>

        <!-- Refrigerated Input -->
        <div class="input-container refrigerated-input">
          <input
            type="radio"
            id="refrigerated"
            name="type"
            value="refrigerated"
            v-model="typeData.type"
          />
          <label for="refrigerated">Refrigerated Warehouse</label>
        </div>

        <!-- Temporary Input -->
        <div class="input-container temporary-input">
          <input
            type="radio"
            id="temporary"
            name="type"
            value="temporary"
            v-model="typeData.type"
          />
          <label for="temporary">Temporary / Demountable Warehouse</label>
        </div>

        <!-- Floor Input -->
        <div class="input-container floor-input">
          <input
            type="radio"
            id="floor"
            name="type"
            value="floor"
            v-model="typeData.type"
          />
          <label for="floor">Logistics floor</label>
        </div>
        <button
          class="next-step-button"
          @click="navigateStep('operations')"
          :disabled="typeData.type === ''"
        >
          Next Step
        </button>
      </div>

      <!-- 2nd Step: Operations -->
      <OperationsStep
        v-if="currentStep === 'operations'"
        v-model:operations-data="operationsData"
        @change-step="navigateStep"
      />

      <button
        v-if="currentStep === 'operations'"
        class="next-step-button"
        @click="navigateStep('location')"
        :disabled="!arePreviousStepsFilled('location')"
      >
        Next Step
      </button>

      <!-- 3rd Step: Location -->
      <div
        class="step-container location-step"
        v-if="currentStep === 'location'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-map-location-dot"></i>
          3rd step: Location</span
        >

        <!-- Country Input -->
        <div
          class="input-container country-input"
          v-if="locationCurrentInput === 'country'"
        >
          <label for="country-input">
            <i class="fa-solid fa-flag"></i>
            Country
          </label>
          <div class="input-and-button">
            <input
              ref="countryInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="countrySearchQuery"
              @input="filterCountries(countrySearchQuery)"
              @keydown="navigateCountryList"
              placeholder="Enter the country"
              id="country-input"
            />
            <button
              :disabled="locationData.country.length === 0"
              @click="navigateInput('location', 'region')"
              id="submit-country"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <ul id="country-list" v-show="filteredCountries.length > 0">
            <li
              v-for="(country, index) in filteredCountries"
              :key="country"
              :class="{ selected: index === selectedCountryIndex }"
              @click="selectCountry(country)"
            >
              {{ country }}
            </li>
          </ul>
        </div>

        <!-- Region Input -->
        <div
          class="input-container region-input"
          v-if="locationCurrentInput === 'region'"
        >
          <label for="region-input">
            <i class="fa-solid fa-location-crosshairs"></i>
            Region / Province / State
          </label>
          <div class="input-and-button">
            <input
              ref="regionInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.region"
              placeholder="Enter the region"
              id="region-input"
            />
            <button
              :disabled="locationData.region.length === 0"
              @click="navigateInput('location', 'city')"
              id="submit-region"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- City Input -->
        <div
          class="input-container city-input"
          v-if="locationCurrentInput === 'city'"
        >
          <label for="city-input">
            <i class="fa-solid fa-city"></i>
            City
          </label>
          <div class="input-and-button">
            <input
              ref="cityInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.city"
              placeholder="Enter the city"
              id="city-input"
            />
            <button
              :disabled="locationData.city.length === 0"
              @click="navigateInput('location', 'zip')"
              id="submit-city"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- ZIP Input -->
        <div
          class="input-container zip-input"
          v-if="locationCurrentInput === 'zip'"
        >
          <label for="zip-input">
            <i class="fa-solid fa-envelope"></i>
            ZIP
          </label>
          <div class="input-and-button">
            <input
              ref="zipInput"
              autocomplete="off"
              type="text"
              v-model="locationData.zip"
              placeholder="Enter the ZIP code"
              id="zip-input"
            />
            <button
              :disabled="locationData.zip.length === 0"
              @click="navigateInput('location', 'address')"
              id="submit-zip"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Address Input -->
        <div
          class="input-container address-input"
          v-if="locationCurrentInput === 'address'"
        >
          <label for="address-input">
            <i class="fa-solid fa-house"></i>
            Address
          </label>
          <div class="input-and-button">
            <input
              ref="addressInput"
              autocapitalize="on"
              autocorrect="off"
              autocomplete="off"
              type="text"
              v-model="locationData.address"
              placeholder="Enter the address"
              id="address-input"
            />
            <button
              :disabled="locationData.address.length === 0"
              @click="navigateStep('dimensions')"
              id="submit-address"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- 4th Step: Dimensions -->
      <div
        class="step-container dimensions-step"
        v-if="currentStep === 'dimensions'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-ruler"></i>
          4th step: Dimensions</span
        >

        <!-- Surface Input -->
        <div
          class="input-container surface-input"
          v-if="dimensionsCurrentInput === 'surface'"
        >
          <label for="surface-input">
            <i class="fa-solid fa-maximize"></i>
            Surface / Area
          </label>
          <div class="input-and-button">
            <input
              ref="surfaceInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.surface"
              placeholder="Enter the surface (in m²)"
              id="surface-input"
            />
            <button
              :disabled="dimensionsData.surface.length === 0"
              @click="navigateInput('dimensions', 'height')"
              id="submit-surface"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Height Input -->
        <div
          class="input-container height-input"
          v-if="dimensionsCurrentInput === 'height'"
        >
          <label for="height-input">
            <i class="fa-solid fa-arrows-up-down"></i>
            Height
          </label>
          <div class="input-and-button">
            <input
              ref="heightInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.height"
              placeholder="Enter the height (in m)"
              id="height-input"
            />
            <button
              :disabled="dimensionsData.height.length === 0"
              @click="navigateInput('dimensions', 'palletPositions')"
              id="submit-height"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <!-- Pallet Positions Input -->
        <div
          class="input-container palletPositions-input"
          v-if="dimensionsCurrentInput === 'palletPositions'"
        >
          <label for="palletPositions-input">
            <i class="fa-solid fa-pallet"></i>
            Pallet Positions
          </label>
          <div class="input-and-button">
            <input
              ref="palletPositionsInput"
              autocomplete="off"
              type="number"
              min="0"
              v-model="dimensionsData.palletPositions"
              placeholder="Enter the pallet positions"
              id="palletPositions-input"
            />
            <button
              :disabled="dimensionsData.palletPositions.length === 0"
              @click="navigateStep('equipment')"
              id="submit-palletPositions"
              class="button"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- 5th Step: Equipment -->
      <EquipmentStep
        v-if="currentStep === 'equipment'"
        v-model:equipment-data="equipmentData"
        @change-step="navigateStep"
      />

      <!-- 6th Step: Storage -->
      <div class="step-container storage-step" v-if="currentStep === 'storage'">
        <span class="step-form-title">
          <i class="fa-solid fa-warehouse-full"></i>
          6th step: Storage Methods</span
        >
        <span class="step-form-subtitle"
          >(Select as many options as needed or skip to the next method if none
          apply)</span
        >
        <!-- a) Input-Group Block Storage -->
        <div
          class="input-group blockStorage-input-group"
          v-if="storageCurrentInput === 'blockStorage'"
        >
          <label for="blockStorage-input-group">
            <i class="fa-solid fa-boxes-stacked"></i>
            Block Storage
          </label>
          <!-- Switches container -->
          <ol class="switches">
            <!-- Rack Push Back input -->
            <li>
              <input
                type="checkbox"
                name="rackPushBack"
                id="rackPushBack-storage"
                v-model="storageData.blockStorage.rackPushBack"
              />
              <label for="rackPushBack-storage">
                <span>Rack Push Back</span>
                <span></span>
              </label>
            </li>

            <!-- Dynamic Rack input -->
            <li>
              <input
                type="checkbox"
                name="dynamicRack"
                id="dynamicRack-storage"
                v-model="storageData.blockStorage.dynamicRack"
              />
              <label for="dynamicRack-storage">
                <span>Dynamic Rack</span>
                <span></span>
              </label>
            </li>
          </ol>
          <button
            @click="navigateInput('storage', 'directStorage')"
            id="submit-blockStorage"
            class="next-step-button"
          >
            Next method
          </button>
        </div>

        <!-- b) Input-Group Direct Storage -->
        <div
          class="input-group directStorage-input-group"
          v-if="storageCurrentInput === 'directStorage'"
        >
          <label for="directStorage-input-group">
            <i class="fa-solid fa-boxes-packing"></i>
            Direct Storage
          </label>
          <!-- Switches container -->
          <ol class="switches">
            <!-- Conventional Rack input -->
            <li>
              <input
                type="checkbox"
                name="conventionalRack"
                id="conventionalRack-storage"
                v-model="storageData.directStorage.conventionalRack"
              />
              <label for="conventionalRack-storage">
                <span>Conventional Rack</span>
                <span></span>
              </label>
            </li>

            <!-- Cantilever Rack input -->
            <li>
              <input
                type="checkbox"
                name="cantileverRack"
                id="cantileverRack-storage"
                v-model="storageData.directStorage.cantileverRack"
              />
              <label for="cantileverRack-storage">
                <span>Cantilever Rack</span>
                <span></span>
              </label>
            </li>

            <!-- Mobile Base Rack input -->
            <li>
              <input
                type="checkbox"
                name="mobileBaseRack"
                id="mobileBaseRack-storage"
                v-model="storageData.directStorage.mobileBaseRack"
              />
              <label for="mobileBaseRack-storage">
                <span>Mobile Base Rack</span>
                <span></span>
              </label>
            </li>

            <!-- Drive In Rack input -->
            <li>
              <input
                type="checkbox"
                name="driveInRack"
                id="driveInRack-storage"
                v-model="storageData.directStorage.driveInRack"
              />
              <label for="driveInRack-storage">
                <span>Drive In Rack</span>
                <span></span>
              </label>
            </li>

            <!-- Pallet Shuttle Rack input -->
            <li>
              <input
                type="checkbox"
                name="palletShuttleRack"
                id="palletShuttleRack-storage"
                v-model="storageData.directStorage.palletShuttleRack"
              />
              <label for="palletShuttleRack-storage">
                <span>Mobile Base Rack</span>
                <span></span>
              </label>
            </li>
          </ol>
          <button
            @click="navigateInput('storage', 'automatedStorage')"
            id="submit-directStorage"
            class="next-step-button"
          >
            Next Method
          </button>
        </div>

        <!-- c) Input-Group Automated Storage -->
        <div
          class="input-group automatedStorage-input-group"
          v-if="storageCurrentInput === 'automatedStorage'"
        >
          <label for="automatedStorage-input-group">
            <i class="fa-solid fa-robot"></i>
            Automated Storage
          </label>
          <!-- Switches container -->
          <ol class="switches">
            <!-- Vertical Carousel input -->
            <li>
              <input
                type="checkbox"
                name="verticalCarousel"
                id="verticalCarousel-storage"
                v-model="storageData.automatedStorage.verticalCarousel"
              />
              <label for="verticalCarousel-storage">
                <span>Vertical Carousel</span>
                <span></span>
              </label>
            </li>

            <!-- Horizontal Carousel input -->
            <li>
              <input
                type="checkbox"
                name="horizontalCarousel"
                id="horizontalCarousel-storage"
                v-model="storageData.automatedStorage.horizontalCarousel"
              />
              <label for="horizontalCarousel-storage">
                <span>Horizontal Carousel</span>
                <span></span>
              </label>
            </li>

            <!-- Stacker Crane input -->
            <li>
              <input
                type="checkbox"
                name="stackerCrane"
                id="stackerCrane-storage"
                v-model="storageData.automatedStorage.stackerCrane"
              />
              <label for="stackerCrane-storage">
                <span>Stacker Crane</span>
                <span></span>
              </label>
            </li>

            <!-- Vertical Shuttle input -->
            <li>
              <input
                type="checkbox"
                name="verticalShuttle"
                id="verticalShuttle-storage"
                v-model="storageData.automatedStorage.verticalShuttle"
              />
              <label for="verticalShuttle-storage">
                <span>Vertical Shuttle</span>
                <span></span>
              </label>
            </li>
          </ol>
          <button
            :disabled="!arePreviousStepsFilled('goods')"
            @click="navigateStep('goods')"
            id="submit-automatedStorage"
            class="next-step-button"
          >
            Next Step
          </button>
        </div>
      </div>

      <!-- 7th Step: Goods -->
      <div class="step-container goods-step" v-if="currentStep === 'goods'">
        <span class="step-form-title">
          <i class="fa-solid fa-box-circle-check"></i>
          7th step: Goods</span
        >
        <span class="step-form-subtitle"
          >(Select which types of goods can you and/or are willing to
          store)</span
        >
        <!-- Switches container -->
        <ol class="switches">
          <!-- Bulk input -->
          <li>
            <input
              type="checkbox"
              name="bulk"
              id="bulk-goods"
              v-model="goodsData.bulk"
            />
            <label for="bulk-goods">
              <span>Bulk</span>
              <span></span>
            </label>
          </li>

          <!-- Dry input -->
          <li>
            <input
              type="checkbox"
              name="dry"
              id="dry-goods"
              v-model="goodsData.dry"
            />
            <label for="dry-goods">
              <span>Dry</span>
              <span></span>
            </label>
          </li>

          <!-- Perishable input -->
          <li>
            <input
              type="checkbox"
              name="perishable"
              id="perishable-goods"
              v-model="goodsData.perishable"
            />
            <label for="perishable-goods">
              <span>Perishable</span>
              <span></span>
            </label>
          </li>

          <!-- Chemical input -->
          <li>
            <input
              type="checkbox"
              name="chemical"
              id="chemical-goods"
              v-model="goodsData.chemical"
            />
            <label for="chemical-goods">
              <span>Chemical</span>
              <span></span>
            </label>
          </li>

          <!-- Raw Materials Input -->
          <li>
            <input
              type="checkbox"
              name="raw"
              id="raw-goods"
              v-model="goodsData.raw"
            />
            <label for="raw-goods">
              <span>Raw Materials</span>
              <span></span>
            </label>
          </li>

          <!-- Construction Materials Input -->
          <li>
            <input
              type="checkbox"
              name="construction"
              id="construction-goods"
              v-model="goodsData.construction"
            />
            <label for="construction-goods">
              <span>Construction Materials</span>
              <span></span>
            </label>
          </li>

          <!-- Machinery Input -->
          <li>
            <input
              type="checkbox"
              name="machinery"
              id="machinery-goods"
              v-model="goodsData.machinery"
            />
            <label for="machinery-goods">
              <span>Machinery</span>
              <span></span>
            </label>
          </li>
        </ol>
        <button
          class="next-step-button"
          @click="navigateStep('licenses')"
          :disabled="!arePreviousStepsFilled('licenses')"
        >
          Next Step
        </button>
      </div>

      <!-- 8th Step: Licenses -->
      <div
        class="step-container licenses-step"
        v-if="currentStep === 'licenses'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-box-circle-check"></i>
          8th step: Licenses</span
        >
        <span class="step-form-subtitle"
          >(Select which licenses and certificates you have)</span
        >
        <!-- Switches container -->
        <ol class="switches">
          <!-- ISO 9001 input -->
          <li>
            <input
              type="checkbox"
              name="iso9001"
              id="iso9001-licenses"
              v-model="licensesData.iso9001"
            />
            <label for="iso9001-licenses">
              <span>ISO 9001</span>
              <span></span>
            </label>
          </li>

          <!-- ISO 14001 input -->
          <li>
            <input
              type="checkbox"
              name="iso14001"
              id="iso14001-licenses"
              v-model="licensesData.iso14001"
            />
            <label for="iso14001-licenses">
              <span>ISO 14001</span>
              <span></span>
            </label>
          </li>

          <!-- ISO 45001 input -->
          <li>
            <input
              type="checkbox"
              name="iso45001"
              id="iso45001-licenses"
              v-model="licensesData.iso45001"
            />
            <label for="iso45001-licenses">
              <span>ISO 45001</span>
              <span></span>
            </label>
          </li>

          <!-- OHSAS 18001 input -->
          <li>
            <input
              type="checkbox"
              name="ohsas18001"
              id="ohsas18001-licenses"
              v-model="licensesData.ohsas18001"
            />
            <label for="ohsas18001-licenses">
              <span>OHSAS 18001</span>
              <span></span>
            </label>
          </li>

          <!-- HACCP Input -->
          <li>
            <input
              type="checkbox"
              name="haccp"
              id="haccp-licenses"
              v-model="licensesData.haccp"
            />
            <label for="haccp-licenses">
              <span>HACCP</span>
              <span></span>
            </label>
          </li>

          <!-- Customs Certificate Input -->
          <li>
            <input
              type="checkbox"
              name="customs"
              id="customs-licenses"
              v-model="licensesData.customs"
            />
            <label for="customs-licenses">
              <span>Customs Certificate</span>
              <span></span>
            </label>
          </li>

          <!-- Chemicals Handling Input -->
          <li>
            <input
              type="checkbox"
              name="chemicalHandling"
              id="chemicalHandling-licenses"
              v-model="licensesData.chemicalHandling"
            />
            <label for="chemicalHandling-licenses">
              <span>Chemicals Handling</span>
              <span></span>
            </label>
          </li>

          <!-- Fire Safety Input -->
          <li>
            <input
              type="checkbox"
              name="fireSafety"
              id="fireSafety-licenses"
              v-model="licensesData.fireSafety"
            />
            <label for="fireSafety-licenses">
              <span>Fire Safety</span>
              <span></span>
            </label>
          </li>

          <!-- BREEAM Certificate Input -->
          <li>
            <input
              type="checkbox"
              name="breeam"
              id="breeam-licenses"
              v-model="licensesData.breeam"
            />
            <label for="breeam-licenses">
              <span>BREEAM Certificate</span>
              <span></span>
            </label>
          </li>

          <!-- LEED Certificate Input -->
          <li>
            <input
              type="checkbox"
              name="leed"
              id="leed-licenses"
              v-model="licensesData.leed"
            />
            <label for="leed-licenses">
              <span>LEED Certificate</span>
              <span></span>
            </label>
          </li>
        </ol>
        <button
          class="next-step-button"
          @click="navigateStep('multimedia')"
          :disabled="!arePreviousStepsFilled('multimedia')"
        >
          Next Step
        </button>
      </div>

      <!-- 9th Step: Multimedia -->
      <div
        class="step-container multimedia-step"
        v-if="currentStep === 'multimedia'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-photo-film"></i>
          9th step: Multimedia
        </span>

        <!-- Images Dropzone -->
        <div
          class="input-container images-input"
          v-if="multimediaCurrentInput === 'images'"
        >
          <label for="images-input">
            <i :class="typeIcon('images')"></i>
            Images
          </label>
          <Dropzone
            @file-upload="handleFileUpload('images', $event)"
            accept="image/*"
            placeholder="Drag or select images"
          />
          <button
            class="next-step-button"
            @click="navigateInput('multimedia', 'videos')"
          >
            Next Input
          </button>
        </div>

        <!-- Videos Dropzone
        <div
          class="input-container videos-input"
          v-if="multimediaCurrentInput === 'videos'"
        >
          <label for="videos-input">
            <i :class="typeIcon('videos')"></i>
            Videos
          </label>
          <Dropzone
            @file-upload="handleFileUpload('videos', $event)"
            accept="video/*"
            placeholder="Drag or select videos"
          />
          <button
            class="next-step-button"
            @click="navigateInput('multimedia', 'blueprints')"
          >
            Next Input
          </button>
        </div>

        Blueprints Dropzone
        <div
          class="input-container blueprints-input"
          v-if="multimediaCurrentInput === 'blueprints'"
        >
          <label for="blueprints-input">
            <i :class="typeIcon('blueprints')"></i>
            Blueprints
          </label>
          <Dropzone
            @file-upload="handleFileUpload('blueprints', $event)"
            accept=".pdf,.doc,.docx,.png,.jpeg"
            placeholder="Drag or select blueprints"
          />
          <button class="next-step-button" @click="navigateStep('description')">
            Next Step
          </button>
        </div> -->
      </div>

      <!-- 10th Step: Description -->
      <div
        class="step-container description-step"
        v-if="currentStep === 'description'"
      >
        <span class="step-form-title">
          <i class="fa-solid fa-text"></i>
          10th step: Description
        </span>

        <!-- Description Input -->
        <div class="input-container description-input">
          <textarea
            ref="descriptionInput"
            autocapitalize="on"
            autocorrect="on"
            autocomplete="off"
            v-model="descriptionData.descriptionText"
            placeholder="Enter the description, please."
            id="description-input"
          ></textarea>
        </div>
        <button
          class="next-step-button"
          @click="submitForm()"
          :disabled="
            !arePreviousStepsFilled('description') ||
            descriptionData.descriptionText.length < 10
          "
        >
          Submit Space
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import countries from "@/assets/location/countries/countries.json";
import StepsNavButtons from "@/components/common/Forms/StepsNavButtons.vue";
import OperationsStep from "@/components/Spaces/Steps/2Operations.vue";
import EquipmentStep from "@/components/Spaces/Steps/5Equipment.vue";
import Dropzone from "@/components/common/Dropzone.vue";
import axios from "axios";

export default {
  name: "RegisterSpaceForm",
  components: { StepsNavButtons, OperationsStep, EquipmentStep, Dropzone },
  data() {
    return {
      currentStep: "type",
      steps: [
        { name: "type", icon: "fa-solid fa-clipboard-question" },
        { name: "operations", icon: "fa-solid fa-screwdriver-wrench" },
        { name: "location", icon: "fa-solid fa-map-location-dot" },
        { name: "dimensions", icon: "fa-solid fa-ruler" },
        { name: "equipment", icon: "fa-solid fa-forklift" },
        { name: "storage", icon: "fa-solid fa-warehouse-full" },
        { name: "goods", icon: "fa-solid fa-box-circle-check" },
        { name: "licenses", icon: "fa-solid fa-file-certificate" },
        { name: "multimedia", icon: "fa-solid fa-photo-film" },
        { name: "description", icon: "fa-solid fa-text" },
      ],

      /* Type Data */
      typeData: {
        type: "",
      },

      /* Operations Data */
      operationsData: {
        eCommerce: false,
        crossDocking: false,
        temporary: false,
        distribution: false,
        consolidation: false,
      },

      /* Location Data */
      locationData: {
        country: "",
        region: "",
        city: "",
        zip: "",
        address: "",
      },
      locationCurrentInput: "country",
      filteredCountries: [],
      countrySearchQuery: "",
      selectedCountryIndex: -1,

      /* Dimensions Data */
      dimensionsData: {
        surface: "",
        height: "",
        palletPositions: "",
      },
      dimensionsCurrentInput: "surface",

      /* Equipment Data */
      equipmentData: {
        //loadingDockAmount: false,
        temperatureHumidityControl: false,
        refrigeratedChamber: false,
        //ammountParkingSpaces: false,
        fireProtectionSystem: false,
        securitySystem: false,
        //maneuveringArea: false,
        //yard: false,
        //office: false,
      },
      equipmentCurrentInput: "switches",

      /* Storage Data */
      storageData: {
        blockStorage: {
          rackPushBack: false,
          dynamicRack: false,
        },
        directStorage: {
          conventionalRack: false,
          mobileBaseRack: false,
          cantileverRack: false,
          driveInRack: false,
          palletShuttleRack: false,
        },
        automatedStorage: {
          verticalCarousel: false,
          horizontalCarousel: false,
          stackerCrane: false,
          verticalShuttle: false,
        },
      },
      storageCurrentInput: "blockStorage",

      /* Goods Type Data */
      goodsData: {
        bulk: false,
        dry: false,
        perishable: false,
        chemical: false,
        raw: false,
        construction: false,
        machinery: false,
      },

      /* Licenses Data */
      licensesData: {
        iso9001: false,
        iso14001: false,
        iso45001: false,
        ohsas18001: false,
        haccp: false,
        customs: false,
        chemicalHandling: false,
        fireSafety: false,
        breeam: false,
        leed: false,
      },

      /* Multimedia Data */
      multimediaData: {
        images: [],
        videos: [],
        blueprints: [],
      },
      multimediaCurrentInput: "images",

      /* Description Data */
      descriptionData: {
        descriptionText: "",
      },
      userId: this.$store.state.user ? this.$store.state.user._id : null,
    };
  },
  methods: {
    navigateStep(step) {
      if (this.arePreviousStepsFilled(step)) {
        this.currentStep = step;
        switch (step) {
          case "location": {
            this.$nextTick(() => {
              this.$refs[`countryInput`].focus();
            });
            break;
          }
          case "dimensions": {
            this.$nextTick(() => {
              this.$refs[`surfaceInput`].focus();
            });
            break;
          }
          case "description": {
            this.$nextTick(() => {
              this.$refs[`descriptionInput`].focus();
            });
            break;
          }
          default:
            break;
        }
      }
    },
    arePreviousStepsFilled(step) {
      switch (step) {
        case "type":
          return true;
        case "operations":
          if (this.typeData.type !== "") {
            return true;
          } else {
            return false;
          }
        case "location":
          return Object.values(this.operationsData).some(
            (operation) => operation
          );
        case "dimensions":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "")
          );
        case "equipment":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every((value) => value !== "")
          );
        case "storage":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every(
                (value) => value !== ""
              ) &&
              Object.values(this.equipmentData).some((value) => value === true)
          );
        case "goods":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every(
                (value) => value !== ""
              ) &&
              Object.values(this.equipmentData).some(
                (value) => value === true
              ) &&
              Object.values(this.operationsData).some(
                (operation) => operation
              ) &&
              (Object.values(this.storageData.blockStorage).some(
                (value) => value
              ) ||
                Object.values(this.storageData.directStorage).some(
                  (value) => value
                ) ||
                Object.values(this.storageData.automatedStorage).some(
                  (value) => value
                ))
          );
        case "licenses":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every(
                (value) => value !== ""
              ) &&
              Object.values(this.equipmentData).some(
                (value) => value === true
              ) &&
              Object.values(this.operationsData).some(
                (operation) => operation
              ) &&
              (Object.values(this.storageData.blockStorage).some(
                (value) => value
              ) ||
                Object.values(this.storageData.directStorage).some(
                  (value) => value
                ) ||
                Object.values(this.storageData.automatedStorage).some(
                  (value) => value
                )) &&
              Object.values(this.goodsData).some((good) => good)
          );
        case "multimedia":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every(
                (value) => value !== ""
              ) &&
              Object.values(this.equipmentData).some(
                (value) => value === true
              ) &&
              Object.values(this.operationsData).some(
                (operation) => operation
              ) &&
              (Object.values(this.storageData.blockStorage).some(
                (value) => value
              ) ||
                Object.values(this.storageData.directStorage).some(
                  (value) => value
                ) ||
                Object.values(this.storageData.automatedStorage).some(
                  (value) => value
                )) &&
              Object.values(this.goodsData).some((good) => good) &&
              Object.values(this.licensesData).some((licenses) => licenses)
          );
        case "description":
          return Object.values(this.operationsData).some(
            (operation) =>
              operation &&
              Object.values(this.locationData).every((value) => value !== "") &&
              Object.values(this.dimensionsData).every(
                (value) => value !== ""
              ) &&
              Object.values(this.equipmentData).some(
                (value) => value === true
              ) &&
              Object.values(this.operationsData).some(
                (operation) => operation
              ) &&
              (Object.values(this.storageData.blockStorage).some(
                (value) => value
              ) ||
                Object.values(this.storageData.directStorage).some(
                  (value) => value
                ) ||
                Object.values(this.storageData.automatedStorage).some(
                  (value) => value
                )) &&
              Object.values(this.goodsData).some((good) => good) &&
              Object.values(this.licensesData).some((licenses) => licenses)
          );
        default:
          return false;
      }
    },
    navigateInput(step, input) {
      if (this.arePreviousInputsFilled(step, input)) {
        this[`${step}CurrentInput`] = input;
        if (step === "location" || step === "dimensions") {
          this.$nextTick(() => {
            this.$refs[`${input}Input`].focus();
          });
        }
      }
    },
    arePreviousInputsFilled(step, input) {
      switch (step) {
        /* Location Step */
        case "location": {
          switch (input) {
            case "country": {
              return true;
            }
            case "region": {
              if (this.locationData.country !== "") {
                return true;
              } else {
                return false;
              }
            }
            case "city": {
              if (
                this.locationData.country !== "" &&
                this.locationData.region !== ""
              ) {
                return true;
              } else {
                return false;
              }
            }
            case "zip": {
              if (
                this.locationData.country !== "" &&
                this.locationData.region !== "" &&
                this.locationData.city !== ""
              ) {
                return true;
              } else {
                return false;
              }
            }
            case "address": {
              if (
                this.locationData.country !== "" &&
                this.locationData.region !== "" &&
                this.locationData.city !== "" &&
                this.locationData.zip !== ""
              ) {
                return true;
              } else {
                return false;
              }
            }
            default:
              return false;
          }
        }

        /* Dimensions Step */
        case "dimensions": {
          switch (input) {
            case "surface": {
              return true;
            }
            case "height": {
              if (this.dimensionsData.surface !== "") {
                return true;
              } else {
                return false;
              }
            }
            case "palletPositions": {
              if (
                this.dimensionsData.surface !== "" &&
                this.dimensionsData.height !== ""
              ) {
                return true;
              } else {
                return false;
              }
            }
            default:
              return false;
          }
        }

        /* Storage Method Step */
        case "storage": {
          return true;
        }

        /* MultiMedia Step */
        case "multimedia": {
          return true;
        }

        default:
          return false;
      }
    },
    filterCountries(countrySearchQuery) {
      if (countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase())
        );
        this.selectedCountryIndex = -1; // Reset index when query changes
      } else {
        this.filteredCountries = [];
      }
    },
    selectCountry(country) {
      this.filteredCountries = [];
      this.countrySearchQuery = country;
      this.locationData.country = country;
      this.selectedCountryIndex = -1;
    },
    /* Navigate Country List With ↑ & ↓ */
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
        } else if (event.key === "Enter") {
          if (this.selectedCountryIndex >= 0) {
            event.preventDefault();
            this.selectCountry(
              this.filteredCountries[this.selectedCountryIndex]
            );
          }
        }
      }
    },

    handleFileUpload(type, event) {
      const files = event.target.files || event;
      this.multimediaData[type] = files;
    },

    /* fa-icon data */
    typeIcon(type) {
      const icons = {
        images: "fa-solid fa-image",
        videos: "fa-solid fa-video",
        blueprints: "fa-solid fa-pen-ruler",
      };
      return icons[type];
    },

    /* Capitalizies first letter */
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    async submitForm() {
      try {
        const payload = {
          typeData: this.typeData,
          operationsData: this.operationsData,
          locationData: this.locationData,
          dimensionsData: this.dimensionsData,
          equipmentData: this.equipmentData,
          storageData: this.storageData,
          goodsData: this.goodsData,
          licensesData: this.licensesData,
          multimediaData: this.multimediaData,
          descriptionData: this.descriptionData,
          corporateId: this.corporateId,
          userId: this.userId,
        };
        const response = await axios.post("/api/spaces/register", payload);
        console.log(response.data);
        this.$router.push({ path: "/your-account" });
      } catch (error) {
        console.error(
          "Error submitting form:",
          error.response?.data || error.message
        );
      }
    },
  },
};
</script>

<style scoped>
/* Inputs Nav Buttons */
.inputs-nav-title {
  display: block;
  margin: 16px;
}
.inputs-nav-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.input-nav-button {
  margin: 0 auto;
  position: relative;
  width: 50px;
  height: 50px;
  color: #ffde59;
  background-color: #004aad;
  border-radius: 50%;
}
.input-nav-button[disabled],
.step-nav-button[disabled] {
  background-color: #a9a9a9;
  color: #004aad;
  cursor: not-allowed;
}
.input-nav-button:after {
  top: 4px;
  left: 52px;
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  z-index: 2;
}
.inputs-nav-buttons .current-button::after {
  top: 12.75px;
  left: 69px;
}
.input-nav-button:hover::after {
  opacity: 1;
}
.input-nav-button[disabled]:after {
  content: "Please, enter all the previous inputs";
  color: #ffde59;
  left: 52px;
}
.input-separator {
  margin: 0 auto;
  height: 12.5px;
  width: 4px;
  border: 2.5px solid #a9a9a9;
}

/* Main Wrapper */
.wrapper {
  display: flex;
  flex-direction: row;
  margin: 50px auto;
}

/* Register Space Form */
.register-space-form {
  position: relative;
  margin: auto;
  width: fit-content;
}
.step-container {
  position: relative;
  margin: auto;
}
.step-form-title {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: #004aad;
  text-align: center;
}
.step-form-subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #004aad;
  text-align: center;
}
input[type="radio"],
input[type="radio"]:checked {
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
}

/* Style for custom radio buttons */
.input-container {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 1rem auto;
}
.input-container input[type="radio"]:before {
  content: "";
  display: inline-block;
  width: 20px; /* Size of the checkbox */
  height: 20px; /* Size of the checkbox */
  border: 1px solid #004aad; /* Border color */
  border-radius: 4px; /* Rounded corners */
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Checked state style */
.input-container input[type="radio"]:checked:before {
  content: "✕";
  color: #ffde59;
  background-color: #004aad;
  display: inline-block;
  width: 20px; /* Size of the checkbox */
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: 800;
  border-radius: 4px; /* Rounded corners */
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}
.next-step-button {
  position: relative;
  padding: 10px 16px;
  color: #ffde59;
  font-weight: 500;
  width: max-content;
  margin: 1rem calc(50% - 57px);
  border: 1px solid #004aad;
  background-color: #004aad;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}
.next-step-button[disabled],
.next-step-button[disabled]:hover {
  cursor: not-allowed;
  background-color: #a9a9a9;
  color: #004aad;
}
.next-step-button:hover {
  color: #004aad;
  background-color: #ffde59;
  border-color: #004aad;
}
.next-step-button[disabled]:hover::after {
  content: "Please, fill the step first";
  font-weight: 400;
  position: absolute;
  background-color: #004aad;
  color: #ffde59;
  width: max-content;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 2;
  pointer-events: none;
}
/* Switch Checkbox */

ol {
  list-style: none;
}

ol label {
  cursor: pointer;
}

[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

.switches {
  max-width: 500px;
  width: 95%;
  margin: 16px auto 0;
  border-radius: 5px;
  color: #004ad0;
}

.switches li {
  position: relative;
  counter-increment: switchCounter;
}

.switches li:not(:last-child) {
  border-bottom: 1px solid #434257;
}

.switches li::before {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  font-size: 2rem;
  font-weight: bold;
  color: #ff4b77;
}

.switches label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.switches span:last-child {
  position: relative;
  width: 50px;
  height: 26px;
  border-radius: 15px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  background: #da0000;
  transition: all 0.3s;
}

.switches span:last-child::before,
.switches span:last-child::after {
  content: "";
  position: absolute;
}

.switches span:last-child::before {
  left: 1px;
  top: 1px;
  width: 24px;
  height: 24px;
  background: #e8e9ed;
  border-radius: 50%;
  z-index: 1;
  transition: transform 0.3s;
}

.switches span:last-child::after {
  top: 50%;
  right: 8px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
  background-size: 12px 12px;
}

.switches [type="checkbox"]:checked + label span:last-child {
  background: #00d084;
}

.switches [type="checkbox"]:checked + label span:last-child::before {
  transform: translateX(24px);
}

.switches [type="checkbox"]:checked + label span:last-child::after {
  width: 14px;
  height: 14px;
  right: auto;
  left: 8px;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/checkmark-switcher.svg);
  background-size: 14px 14px;
}

/* Location Step */
.input-and-button {
  margin: 16px 0 0 0;
  display: flex;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  border-radius: 8px;
  flex-direction: row;
}
.location-step .input-container,
.dimensions-step .input-container {
  padding-left: 0px !important;
  position: relative;
}
.location-step input,
.dimensions-step input {
  border-radius: 8px 0 0 8px;
  width: 400px;
  margin: auto;
  padding: 0.67rem 1rem;
}
#country-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
  max-height: 333px;
  overflow-y: auto;
  background-color: white;
  border-top: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  list-style-type: none;
}
.location-step ul li {
  padding: 0.67rem 1rem;
  background: white;
}

.location-step ul li:hover,
.location-step ul .selected {
  background-color: #004aad;
  color: #ffde59;
  cursor: pointer;
}

.location-step .button,
.dimensions-step .button {
  padding: 0.67rem 1rem;
  background-color: #004aad;
  border-radius: 0 8px 8px 0;
  color: #ffde59;
}
.location-step .button:hover,
.dimensions-step .button:hover {
  background-color: #ffde59;
  color: #004aad;
}

.location-step ul li:first-child {
  border-radius: 8px 8px 0 0;
}
.location-step ul li:last-child {
  border-radius: 0 0 8px 8px;
}
.location-step ul li:first-child:last-child {
  border-radius: 8px;
}
.location-step .input-container label,
.dimensions-step .input-container label,
.input-group > label,
.multimedia-step .input-container label {
  color: #004aad;
  font-weight: 500;
  font-size: large;
  margin: 16px 0 16px 50px;
}
.storage-step .step-form-subtitle {
  margin-bottom: 16px;
}
.button[disabled] {
  position: relative;
  content: "";
  background-color: #a9a9a9;
  color: #004aad;
  cursor: not-allowed;
}
.button[disabled]:hover {
  background-color: #a9a9a9;
  color: #004aad;
  cursor: not-allowed;
}
.button[disabled]:hover::after {
  content: "Please, fill the field";
  position: absolute;
  background-color: #004aad;
  color: #ffde59;
  width: max-content;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 2;
  pointer-events: none;
}

/* Storage Step */
#submit-automatedStorage[disabled]:hover::after {
  content: "Please, select at least one method of storagement";
}

/* Multimedia Step */
.input-file-container {
  width: 100%;
  height: 167px;
  display: flex;
  cursor: pointer;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: 2px dashed #004aad;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.multimedia-step .input-container :where(i, p) {
  color: #004aad;
}

.input-file-container > i {
  font-size: 50px;
}
.input-file-container > p {
  margin-top: 15px;
  font-size: 16px;
}
::selection {
  color: #fff;
  background: #004aad;
}
section .row {
  margin-bottom: 10px;
  background: #e9f0ff;
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section .row i {
  color: #004aad;
  font-size: 30px;
}
section .details span {
  font-size: 14px;
}
.progress-area .row .content {
  width: 100%;
  margin-left: 15px;
}
.progress-area .details {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: space-between;
}
.progress-area .content .progress-bar {
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 30px;
}
.content .progress-bar .progress {
  height: 100%;
  width: 0%;
  background: #004aad;
  border-radius: inherit;
}
.uploaded-area {
  max-height: 232px;
  overflow-y: scroll;
}
.uploaded-area.onprogress {
  max-height: 150px;
}
.uploaded-area::-webkit-scrollbar {
  width: 0px;
}
.uploaded-area .row .content {
  display: flex;
  align-items: center;
}
.uploaded-area .row .details {
  display: flex;
  margin-left: 15px;
  flex-direction: column;
}
.uploaded-area .row .details .size {
  color: #404040;
  font-size: 11px;
}
.uploaded-area i.fa-check {
  font-size: 16px;
}
.description-step textarea {
  border-radius: 5px;
  width: 500px;
  height: 200px; /* Adjust the height as needed */
  margin: auto;
  padding: 0.67rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #004aad; /* Customize the border color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  resize: none;
}
</style>
