<template>
  <div class="register-or-service">
    <Header />
    <RegisterSpaceOrService />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/common/Header.vue";
import RegisterSpaceOrService from "@/components/others/RegisterSpaceOrService.vue";
import Footer from "@/components/common/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegisterSpaceOrServiceView",
  metaInfo: {
    title: "Register Your Space | Biglo",
  },
  components: {
    Header,
    RegisterSpaceOrService,
    Footer,
  },
});
</script>
<style scoped>
.register-or-service {
  max-width: 90vw;
  min-height: 80vh;
  margin: 0 auto;
}
</style>
