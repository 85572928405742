<template>
  <div class="register-service">
    <Header />
    <RegisterServiceForm />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/common/Header.vue";
import RegisterServiceForm from "@/components/Services/RegisterServiceForm.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "SpaceRegistry",
  metaInfo: {
    title: "Space Registry | Biglo",
  },
  components: {
    Header,
    RegisterServiceForm,
    Footer,
  },
};
</script>
<style scoped>
.register-service {
  max-width: 90vw;
  min-height: 80vh;
  margin: 0 auto;
}
</style>
