<template>
  <div class="flex items-center">
    <!-- Separator -->
    <NavButtonSeparator
      v-if="showSeparator"
      :orientation="orientation"
      :separatorClass="separatorClass"
    />

    <!-- Step Button -->
    <NavButton
      :stepName="stepName"
      :isCurrent="isCurrent"
      :isActive="isActive"
      :iconClass="iconClass"
      @navigate="$emit('navigate', $event)"
    />
  </div>
</template>

<script>
import NavButton from "./NavButton.vue";
import NavButtonSeparator from "./NavButtonSeparator.vue";

export default {
  name: "StepNavButton",
  components: { NavButton, NavButtonSeparator },
  props: {
    stepName: { type: String, required: true },
    isCurrent: { type: Boolean, required: true },
    isActive: { type: Boolean, required: true },
    iconClass: { type: String, required: true },
    separatorClass: { type: String, default: "" },
    showSeparator: { type: Boolean, default: false },
    orientation: {
      type: String,
      default: "horizontal",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
  },
};
</script>

<style scoped>
.separator-blue {
  border-color: #004aad;
  background-color: #004aad;
}
</style>
