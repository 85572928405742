<template>
  <div
    class="service-management font-medium text-darkblue p-5 max-w-screen-xl mx-auto"
  >
    <h1
      class="text-center text-darkblue mb-5 text-2xl border-b-2 border-darkblue pb-2"
    >
      <i class="fa-solid fa-gears"></i>
      Services Management
    </h1>
    <div
      v-if="services.length"
      class="service-list flex flex-wrap gap-5 justify-between"
    >
      <div
        v-for="service in services"
        :key="service._id"
        class="service-item bg-gray-100 rounded-lg overflow-hidden shadow-lg transition-transform duration-300 ease-in-out w-full max-w-lg cursor-pointer hover:transform hover:-translate-y-1 hover:shadow-xl"
        @click="viewDetails(service)"
      >
        <img
          :src="getSpaceImage(service)"
          alt="Service Image"
          class="service-image w-1/3 h-full object-cover"
        />
        <div class="service-details p-4 flex flex-col justify-center w-2/3">
          <h2 class="text-darkblue mb-2 text-lg">
            {{ service.typeData.type }} service
          </h2>
          <p class="text-black mb-1">
            {{ service.locationData.city }}, {{ service.locationData.country }}
          </p>
          <p class="text-black text-base">
            {{ service.dimensionsData.surface }}m²
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="no-services text-center text-black text-lg p-5 bg-gray-100 rounded-lg shadow-lg mt-5"
    >
      No services registered for this corporate account.
    </div>
    <router-link
      to="/service-registry"
      class="register-new-service inline-flex items-center justify-center mt-5 text-darkblue bg-yellow hover:bg-opacity-75 px-4 py-2 rounded-lg shadow-md transition-colors duration-300"
    >
      <i class="fa-solid fa-plus mr-2"></i>
      Register New Service
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      services: [],
    };
  },
  computed: {
    ...mapGetters(["corporateId"]),
  },
  methods: {
    ...mapActions(["fetchCorporateDetails"]),
    async fetchSpaces() {
      if (!this.corporateId) {
        await this.fetchCorporateDetails();
      }

      if (this.corporateId) {
        try {
          const response = await axios.get(
            `/api/services/corporate/${this.corporateId}`
          );
          this.services = response.data;
        } catch (error) {
          console.error("Error loading services:", error);
        }
      }
    },
    getSpaceImage(service) {
      if (service.multimediaData.images.uploaded.length > 0) {
        return service.multimediaData.images.uploaded[0]; // Use the first uploaded image
      } else {
        return require("@/assets/images/free-advertisement.png");
      }
    },
    viewDetails(service) {
      // Logic to navigate to the detailed view
      this.$router.push({
        name: "SpaceDetails",
        params: { spaceId: service._id },
      });
    },
  },
  mounted() {
    this.fetchSpaces();
  },
};
</script>
<style scoped></style>
