<template>
  <div class="bg-white p-5 mx-auto rounded-lg shadow-md max-w-lg">
    <label
      for="user-form"
      class="text-xl font-medium text-center text-darkblue mb-8 flex items-center justify-center space-x-2"
    >
      <i class="fa-solid fa-user fa-lg"></i>
      <span>User Registry</span>
    </label>
    <form id="user-form" @submit.prevent="handleSubmit">
      <label for="name" class="block text-darkblue font-bold mb-1 mt-4"
        >Company Name</label
      >
      <input
        class="w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
        type="text"
        id="name"
        v-model="form.name"
        required
      />

      <label for="email" class="block text-darkblue font-bold mb-1 mt-4"
        >E-mail</label
      >
      <input
        class="w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
        type="email"
        id="email"
        v-model="form.email"
        required
      />
      <span v-if="emailErrorMessage" class="text-redError block my-2">{{
        emailErrorMessage
      }}</span>

      <label for="telephone" class="block text-darkblue font-bold mb-1 mt-4"
        >Telephone</label
      >
      <input
        class="min-sm:min-w-[29.5rem] sm:min-w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
        type="tel"
        ref="telephone"
        name="telephone"
        id="telephone"
        v-model="form.telephone"
        @input="restrictToDigits"
      />

      <label for="website" class="block text-darkblue font-bold mb-1 mt-4"
        >Website Address</label
      >
      <input
        type="url"
        id="website"
        v-model="form.website"
        required
        class="w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
      />

      <div class="relative mt-4">
        <label for="country" class="block text-darkblue font-bold mb-1"
          >Country</label
        >
        <input
          type="text"
          id="country"
          v-model="countrySearchQuery"
          @input="filterCountries"
          @keydown="navigateCountryList"
          @focus="showCountryList"
          required
          class="w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
        />
        <ul
          v-if="isCountryListVisible && filteredCountries.length > 0"
          ref="countryList"
          class="absolute top-full left-0 w-full bg-white border border-darkblue rounded-lg max-h-80 overflow-y-auto z-10"
        >
          <li
            v-for="(country, index) in filteredCountries"
            :key="country"
            :class="{
              'bg-darkblue text-yellow': index === selectedCountryIndex,
              'hover:bg-darkblue hover:text-yellow cursor-pointer': true,
            }"
            @click="selectCountry(country)"
            class="p-3"
          >
            {{ country }}
          </li>
        </ul>
      </div>
      <span v-if="countryErrorMessage" class="text-redError block my-2">{{
        countryErrorMessage
      }}</span>

      <label for="nif" class="block text-darkblue font-bold mb-1 mt-4"
        >NIF/Tax ID</label
      >
      <input
        type="text"
        id="nif"
        v-model="form.nif"
        required
        class="w-full p-3 border border-grey rounded-lg focus:outline-none focus:border-darkblue focus:shadow-md"
      />

      <!-- File input for uploading a company logo or document -->
      <label
        for="logo-file-upload"
        class="block text-darkblue font-bold mb-1 mt-4"
        >Logo</label
      >
      <DropZone @file-uploaded="handleFileUploaded" class="mb-5" />

      <div>
        <label for="password" class="block text-darkblue font-bold mb-1"
          >Password</label
        >
        <div class="flex">
          <input
            :type="passwordFieldType"
            autocomplete="off"
            class="w-full p-3 border border-grey rounded-l-lg focus:outline-none focus:border-darkblue focus:shadow-md"
            name="password"
            id="password"
            v-model="form.password"
            required
          />
          <button
            type="button"
            class="min-w-[50px] p-3 bg-darkblue text-yellow rounded-r-lg border border-l-0 border-darkblue hover:bg-yellow hover:text-darkblue"
            @click="togglePassword"
          >
            <i :class="passwordIcon"></i>
          </button>
        </div>
      </div>
      <span
        v-if="!isPasswordComplex() && form.password.length > 0"
        class="text-redError text-center block mt-2"
      >
        {{ passwordErrorMessage }}
      </span>

      <button
        type="submit"
        class="w-full mt-10 p-3 bg-darkblue text-yellow rounded-lg font-medium text-lg hover:bg-yellow hover:text-darkblue"
      >
        Register User
      </button>
    </form>

    <!-- Include NotificationCustom component for notifications -->
    <NotificationCustom
      v-if="notificationVisible"
      :type="notificationType"
      :message="notificationMessage"
      @hide="notificationVisible = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "vue-toastification/dist/index.css";
import countries from "@/assets/location/countries/countries.json";
import NotificationCustom from "@/components/common/NotificationCustom.vue";
import DropZone from "@/components/common/Dropzone.vue";

export default {
  name: "RegisterUser",
  components: {
    DropZone,
    NotificationCustom,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        telephoneCountryPrefix: "",
        telephone: "",
        website: "",
        country: "",
        nif: "",
        logo: "",
        password: "",
      },
      telephoneErrorMessage: "",
      passwordErrorMessage: "",
      countryErrorMessage: "",
      emailErrorMessage: "",
      passwordFieldType: "password",
      passwordIcon: "fa-solid fa-eye-slash",
      selectedFile: null,
      countrySearchQuery: "",
      filteredCountries: [],
      selectedCountryIndex: -1,
      isCountryListVisible: false,
      notifications: [],
    };
  },
  methods: {
    async handleSubmit() {
      try {
        console.log("Form Data:", this.form); // Log form data to check structure
        if (!this.isPasswordComplex()) {
          this.addNotification("error", this.passwordErrorMessage);
          return;
        }
        if (!this.isPhoneValid()) {
          this.addNotification("error", this.telephoneErrorMessage);
          return;
        }
        if (!this.isCountryValid()) {
          this.addNotification("error", this.countryErrorMessage);
        }
        if (!this.isEmailValid()) {
          this.addNotification("error", this.emailErrorMessage);
        }
        const response = await axios.post("/api/users/register", this.form);
        this.addNotification("success", response.data.message);

        // Redirect to a confirmation page or the user's account
        this.$router.push({ name: "your-account" });
      } catch (error) {
        console.error("Error during registration:", error);
        if (error.response) {
          console.log("Backend Error:", error.response.data);
          this.addNotification("error", error.response.data.error);
        } else {
          this.addNotification("error", "An error occurred while registering.");
        }
      }
    },
    restrictToDigits(event) {
      const input = event.target.value;
      this.form.telephone = input.replace(/\D/g, ""); // Restrict to digits
    },
    isPhoneValid() {
      const phoneRegex = /^[0-9]{9,15}$/;
      if (!phoneRegex.test(this.form.telephone)) {
        this.telephoneErrorMessage =
          "Phone number must be between 9 and 15 digits.";
        return false;
      } else {
        this.telephoneErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },
    initializePhoneInput() {
      this.$nextTick(() => {
        if (this.iti) {
          this.iti.destroy(); // Destroy any existing instance
        }

        const iti = intlTelInput(this.$refs.telephone, {
          initialCountry: this.form.telephoneCountryPrefix || "auto",
          geoIpLookup: (callback) => {
            fetch("https://ipinfo.io/json")
              .then((resp) => resp.json())
              .then((resp) => {
                const countryCode = resp && resp.country ? resp.country : "us";
                callback(countryCode);
              })
              .catch(() => callback("us"));
          },
          autoHideDialCode: false,
          nationalMode: false,
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });

        iti.promise.then(() => {
          // Set the country prefix based on selected country
          this.form.telephoneCountryPrefix =
            iti.getSelectedCountryData().dialCode || "1";
          console.log(
            "Country prefix set to:",
            this.form.telephoneCountryPrefix
          );

          this.$refs.telephone.addEventListener("countrychange", () => {
            this.form.telephoneCountryPrefix =
              iti.getSelectedCountryData().dialCode || "1";
            console.log(
              "Country prefix changed to:",
              this.form.telephoneCountryPrefix
            );
          });
        });

        this.iti = iti; // Save reference to destroy later if needed
      });
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordIcon =
        this.passwordFieldType === "password"
          ? "fa-solid fa-eye-slash"
          : "fa-solid fa-eye";
    },
    isPasswordComplex() {
      if (!this.form.password) {
        return true;
      }
      const symbolRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const password = this.form.password;
      if (password.length < 10) {
        this.passwordErrorMessage =
          "Password must contain at least 10 characters";
        return false;
      } else if (!symbolRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one special symbol";
        return false;
      } else if (!uppercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one uppercase letter";
        return false;
      } else if (!lowercaseRegex.test(password)) {
        this.passwordErrorMessage =
          "Password must contain at least one lowercase letter";
        return false;
      } else if (!numberRegex.test(password)) {
        this.passwordErrorMessage = "Password must contain at least one number";
        return false;
      } else {
        this.passwordErrorMessage = ""; // Clear error message if valid
        return true;
      }
    },

    // Handle the file upload success and store the file ID in the form
    handleFileUploaded(fileId) {
      this.form.logo = fileId;
      console.log("File uploaded successfully, ID:", fileId);
    },

    // Filter countries based on search query
    filterCountries() {
      if (this.countrySearchQuery.length > 0) {
        this.filteredCountries = countries.filter((country) =>
          country
            .toLowerCase()
            .startsWith(this.countrySearchQuery.toLowerCase())
        );
        this.selectedCountryIndex = -1; // Reset index when query changes
        this.isCountryListVisible = true;
      } else {
        this.filteredCountries = [];
        this.isCountryListVisible = false;
      }
    },

    // Select a country from the filtered list
    selectCountry(country) {
      this.filteredCountries = [];
      this.countrySearchQuery = country;
      this.form.country = country;
      this.selectedCountryIndex = -1;
      this.isCountryListVisible = false;
    },

    // Navigate through the filtered countries list using keyboard
    navigateCountryList(event) {
      if (this.filteredCountries.length > 0) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex + 1) % this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.selectedCountryIndex =
            (this.selectedCountryIndex - 1 + this.filteredCountries.length) %
            this.filteredCountries.length;
          this.scrollToSelectedCountry();
        } else if (event.key === "Enter" && this.selectedCountryIndex >= 0) {
          event.preventDefault();
          this.selectCountry(this.filteredCountries[this.selectedCountryIndex]);
        } else if (event.key === "Escape") {
          // Close the dropdown on Escape key press
          this.isCountryListVisible = false;
          this.selectedCountryIndex = -1; // Reset index
        }
      }
    },
    showCountryList() {
      this.isCountryListVisible = true;
    },
    hideCountryList() {
      this.isCountryListVisible = false;
    },
    scrollToSelectedCountry() {
      this.$nextTick(() => {
        const list = this.$refs.countryList;
        const selectedOption = list.children[this.selectedCountryIndex];
        if (selectedOption) {
          list.scrollTop =
            selectedOption.offsetTop -
            list.clientHeight / 2 +
            selectedOption.clientHeight / 2;
        }
      });
    },
    // Check if selected country is valid
    isCountryValid() {
      if (this.form.country && !countries.includes(this.countrySearchQuery)) {
        this.countryErrorMessage = "Selected country is not valid.";
        return false;
      }
      this.countryErrorMessage = ""; // Clear error if valid
      return true;
    },

    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.form.email.includes("+")) {
        this.emailErrorMessage = 'This field cannot contain "+"';
        return false;
      } else if (!emailRegex.test(this.form.email)) {
        this.emailErrorMessage = "Invalid email format";
        return false;
      } else {
        this.emailErrorMessage = "";
        return true;
      }
    },

    // Add a notification
    addNotification(message, type, duration = 10000) {
      this.notifications.push({ message, type, duration });
      setTimeout(() => {
        this.notifications.shift();
      }, duration);
    },
  },
  mounted() {
    this.initializePhoneInput();
  },
};
</script>

<style scoped></style>
