<template>
  <div class="flex flex-col items-center justify-center bg-gray-100 my-20">
    <!-- Option Cards (displayed horizontally, now bigger, responsive, and using platform colors) -->
    <div class="flex flex-wrap justify-center gap-8 w-full">
      <!-- Register Space Card (dark blue) -->
      <router-link
        to="/space-registry"
        class="group bg-[#004aad] text-white hover:shadow-2xl transform hover:scale-105 transition-all duration-300 rounded-lg w-56 h-56 flex flex-col items-center justify-center"
      >
        <img
          src="@/assets/images/space-icon.png"
          alt="Register Space"
          class="w-32 h-32 mb-4"
        />
        <span class="font-semibold">Register Space</span>
      </router-link>

      <!-- Register Service Card (yellow) -->
      <router-link
        to="/service-registry"
        class="group bg-[#ffde59] text-black hover:shadow-2xl transform hover:scale-105 transition-all duration-300 rounded-lg w-56 h-56 flex flex-col items-center justify-center"
      >
        <img
          src="@/assets/images/service-icon.png"
          alt="Register Service"
          class="w-32 h-32 mb-4"
        />
        <span class="font-semibold">Register Service</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterSpaceOrService",
};
</script>

<style scoped>
/* No additional styles for now, as Tailwind handles the styling */
</style>
