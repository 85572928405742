<template>
  <div class="step-container equipment-step">
    <span class="step-form-title">
      <i class="fa-solid fa-forklift"></i>
      5th Step: Equipment
    </span>
    <span
      class="step-form-subtitle"
      v-if="equipmentCurrentInput === 'switches'"
    >
      (Select as much equipment as you have or are willing to provide)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      v-if="equipmentCurrentInput === 'switches'"
      :options="equipmentOptions"
      :model-value="localEquipmentData"
      @update:modelValue="updateEquipmentData"
    />

    <button
      class="next-step-button"
      @click="navigateNext"
      :disabled="!isNextStepEnabled"
    >
      Next Step
    </button>
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "EquipmentStep",
  components: { SwitchesCheckboxes },
  props: {
    equipmentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localEquipmentData: { ...this.equipmentData },
      equipmentOptions: [
        {
          name: "temperatureHumidityControl",
          label: "Temperature & Humidity Control",
          value: false,
        },
        {
          name: "refrigeratedChamber",
          label: "Refrigerated Chamber",
          value: false,
        },
        {
          name: "fireProtectionSystem",
          label: "Fire Protection System",
          value: false,
        },
      ],
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    equipmentData: {
      deep: true,
      handler(newVal) {
        this.localEquipmentData = { ...newVal };
      },
    },
  },
  methods: {
    updateEquipmentData(updatedData) {
      this.localEquipmentData = updatedData;
      // Emit the update to parent component
      this.$emit("update:equipmentData", updatedData);
    },
  },
};
</script>
