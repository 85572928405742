<template>
  <Header />
  <div class="container100svh-352px">
    <VerificateUser />
  </div>
  <Footer />
</template>
<script>
import Header from "@/components/common/Header.vue";
import VerificateUser from "@/components/SignIn/VerificateUser.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "UserVerificationView",
  components: {
    Header,
    VerificateUser,
    Footer,
  },
};
</script>
<style>
.container100svh-352px {
  min-height: calc(100svh - 342px);
}
</style>
