<template>
  <div
    class="bg-yellow text-black p-2.5 rounded-md max-w-md text-center hover:bg-[#f7b500]"
  >
    <p>
      Please verify your email. Check your inbox or
      <button
        class="font-medium text-darkblue hover:underline"
        @click="resendVerification"
        :disabled="loading"
      >
        {{ loading ? "Resending..." : "Resend verification" }}
      </button>
    </p>
    <p
      class="text-greenSuccess font-medium"
      v-if="message"
      :class="{ 'text-redError': isError }"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "RemindEmailVerification",
  data() {
    return {
      loading: false, // Button loading state
      message: "", // Success or error message
      isError: false, // Whether the message is an error
    };
  },
  computed: {
    ...mapState(["user"]), // Map Vuex user state (ensure you have user in Vuex)
  },
  methods: {
    async resendVerification() {
      this.loading = true; // Set loading state

      try {
        const response = await axios.post("/api/users/resend-verification", {
          userId: this.user._id,
        });

        this.message = response.data.message; // Set success message
        this.isError = false; // Not an error
      } catch (error) {
        this.message =
          error.response?.data?.error || "An error occurred. Please try again.";
        this.isError = true; // Set error flag
      } finally {
        this.loading = false; // Reset loading state
      }
    },
  },
};
</script>

<style scoped></style>
