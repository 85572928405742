<template>
  <Header />
  <div class="register-view">
    <RegisterUser />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/common/Header.vue";
import RegisterUser from "@/components/SignIn/RegisterUser.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  metaInfo: {
    title: "User Registry | Biglo",
  },
  name: "UserRegister",
  components: {
    Header,
    RegisterUser,
    Footer,
  },
};
</script>

<style scoped></style>
