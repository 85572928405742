<template>
  <!-- Header section with shadow effect -->
  <div class="shadow-md">
    <Header />
  </div>

  <!-- Wrapper for main account content, centered with max-width control -->
  <div class="mt-4 max-w-[90vw] md:max-w-full md:m-4 mx-auto">
    <YourAccount />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import YourAccount from "@/components/Account/YourAccount.vue";

export default {
  name: "YourAccountView",
  components: {
    Header,
    YourAccount,
  },
};
</script>

<style scoped></style>
