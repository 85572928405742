<template>
  <div
    :class="[
      'border border-grey bg-grey',
      orientation === 'vertical' ? 'h-[12.5px] w-1' : 'w-[12.5px] h-1',
      separatorClass,
    ]"
  ></div>
</template>

<script>
export default {
  name: "NavButtonSeparator",
  props: {
    orientation: {
      type: String,
      default: "horizontal",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
    separatorClass: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
