<template>
  <section class="articles">
    <div class="flex_container">
      <router-link to="/register-space-or-service" class="article">
        <div class="article_content">
          <img
            loading="lazy"
            src="@/assets/images/free-advertisement.png"
            class="article_image"
            alt="Free Advertisement"
          />
          <div class="article_text_container">
            <h2 class="article_title">Publish your space for free!</h2>
            <p class="article_description">
              The first advertisement you upload to Biglo is free.
            </p>
            <p class="article_description">
              Take advantage of this special offer to showcase your space.
            </p>
          </div>
        </div>
      </router-link>

      <a href="/space-pricing" class="article">
        <div class="article_content">
          <img
            loading="lazy"
            src="@/assets/images/warehouse_pricing_calculator.png"
            class="article_image"
            alt="Space Pricing Calculator"
          />
          <div class="article_text_container">
            <h2 class="article_title">How much is your space worth?</h2>
            <p class="article_description">
              We help you set the fairest range of prices for your space.
            </p>
            <p class="article_description">
              Get our free and quick estimation!
            </p>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSectionArticles",
};
</script>

<style scoped>
:root {
  --clr-yellow: yellow;
  --clr-darkblue: darkblue;
  --clr-white: white;
}

.articles {
  display: grid;
  gap: 1.25rem;
  margin: 1.25rem auto;
  max-width: 90vw;
  width: 100%;
}

.flex_container {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

.article {
  display: block;
  color: var(--clr-darkblue);
  background-color: var(--clr-white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--clr-darkblue);
  border-radius: 0.5rem;
  overflow: hidden;
  transition: box-shadow 0.3s;
  width: calc(50% - 0.625rem); /* Taking into account the gap */
}

.article:hover {
  box-shadow: 0 0 0 3px var(--clr-yellow);
}

.article_content {
  display: flex;
  height: calc(
    (90vw / 2) / 3
  ); /* x / 3 (x = 3y) and 50% of the container width */
}

.article_image {
  width: 33.33%;
  height: 100%;
  object-fit: cover;
}

.article_text_container {
  width: 66.67%;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.article_title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.2;
}

.article_description {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.article_how_we_work {
  grid-column: span 2;
}

.article_how_we_work .article_content {
  flex-direction: column;
  height: auto;
}

.article_how_we_work .article_image_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.article_how_we_work .article_image {
  width: 100%;
  height: auto;
}

@media (max-width: 985px) {
  .articles {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .flex_container {
    flex-direction: column;
  }

  .article {
    width: 100%;
  }

  .article_content {
    flex-direction: column;
    height: auto;
  }

  .article_image {
    width: 100%;
    height: auto;
  }

  .article_text_container {
    padding: 1rem;
    width: 100%;
  }

  .article_title {
    font-size: 1.25rem;
  }

  .article_description {
    font-size: 0.875rem;
  }

  .article_how_we_work .article_content {
    flex-direction: column;
    height: auto;
  }

  .article_how_we_work .article_image_container {
    width: 100%;
  }

  .article_how_we_work .article_image {
    width: 100%;
  }
}
</style>
