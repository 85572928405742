<template>
  <div class="flex flex-col items-center justify-center w-full">
    <img
      v-if="imageUrl"
      :src="imageUrl"
      alt="Fetched Image"
      class="object-cover rounded-lg shadow-lg max-w-full max-h-full"
    />
    <p v-else-if="loading" class="text-gray-500">Loading image...</p>
    <p v-else class="text-red-500">Failed to load image.</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageView",
  props: {
    imageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: null,
      loading: true,
      error: false,
    };
  },
  created() {
    this.fetchImage();
  },
  watch: {
    imageId: {
      immediate: true,
      handler() {
        this.fetchImage();
      },
    },
  },
  methods: {
    async fetchImage() {
      this.loading = true;
      this.error = false;
      try {
        const response = await axios.get(`/api/images/${this.imageId}`, {
          responseType: "blob",
        });
        this.imageUrl = URL.createObjectURL(response.data);
      } catch (error) {
        console.error("Failed to load image:", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  beforeUnmount() {
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
    }
  },
};
</script>

<style scoped></style>
