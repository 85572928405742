import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apiClient from "@/api";
import axios from "axios";

// Import global styles
import "./assets/global.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "intl-tel-input/build/css/intlTelInput.css";

// Syncfusion license registration
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(process.env.VUE_APP_SYNCFUSION_LICENSE);

// Retrieve JWT token from local storage
const token = localStorage.getItem("jwt_token");

// If token exists, set Authorization header globally for both axios and apiClient
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Set up router navigation guards
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Biglo";
  next();
});

// Create and mount Vue application
const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
