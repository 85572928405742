<template>
  <!-- Individual Navigation Button -->
  <button
    :data-text="stepName"
    @click="$emit('navigate', stepName)"
    :disabled="!isActive"
    :class="[
      'relative flex justify-center items-center transition-all duration-150 rounded-full border border-darkblue flex-shrink-0',
      isActive
        ? 'text-yellow bg-darkblue hover:bg-yellow hover:text-darkblue'
        : 'bg-[#a9a9a9] text-darkblue cursor-not-allowed',
      isCurrent ? 'min-w-16 min-h-16' : 'min-w-12 min-h-12',
      'step-nav-tooltip',
    ]"
  >
    <i :class="[iconClass, isCurrent ? 'text-2xl' : 'text-lg']"></i>
  </button>
</template>

<script>
export default {
  name: "NavButton",
  props: {
    stepName: { type: String, required: true },
    isCurrent: { type: Boolean, required: true },
    isActive: { type: Boolean, required: true },
    iconClass: { type: String, required: true },
  },
};
</script>

<style scoped>
/* Tooltip style */
.step-nav-tooltip:after {
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
  content: attr(data-text);
  position: absolute;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  opacity: 0;
  background-color: #004aad;
  color: #ffde59;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.step-nav-tooltip:hover::after {
  opacity: 1;
}
.step-nav-tooltip:disabled::after {
  content: "Please, enter all the previous steps";
  color: #ffde59;
}
</style>
