<template>
  <div class="flex flex-col items-center justify-center w-full">
    <!-- Dropzone area -->
    <label
      class="border-2 border-dashed border-darkblue rounded-lg p-8 w-full max-w-lg text-center cursor-pointer hover:border-yellow hover:bg-darkblue transition-all duration-200 ease-in-out text-darkblue hover:text-yellow"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
    >
      <!-- Hidden file input -->
      <input
        type="file"
        ref="fileInput"
        class="hidden"
        accept="image/*"
        @change="handleFileSelect"
      />
      <i class="fas fa-file-arrow-up fa-3x"></i>
      <p v-if="!selectedFile" class="mt-4">
        <span class="lg:hidden">Drag & Drop or </span>Click to Upload an Image
      </p>
      <p v-else class="mt-4 text-black font-semibold">
        {{ selectedFile.name }}
      </p>
    </label>

    <!-- Upload progress and status messages -->
    <p
      v-if="uploadProgress > 0 && uploadProgress < 100"
      class="mt-4 text-darkblue"
    >
      Uploading: {{ uploadProgress }}%
    </p>
    <p v-if="uploadSuccess" class="mt-4 text-greenSuccess">
      File uploaded successfully!
    </p>
    <p v-if="uploadError" class="mt-4 text-redError">{{ uploadError }}</p>
  </div>
</template>

<script>
import apiClient from "@/api"; // Import the API client

export default {
  name: "DropZone",
  data() {
    return {
      selectedFile: null,
      isUploading: false,
      uploadProgress: 0,
      uploadSuccess: false,
      uploadError: null,
    };
  },
  methods: {
    // Handle file selection from input and automatically upload
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.validateFile(file);
      if (this.selectedFile) {
        this.uploadFile(); // Automatically trigger the upload
      }
    },

    // Handle drag-and-drop file selection
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.validateFile(file);
      if (this.selectedFile) {
        this.uploadFile(); // Automatically trigger the upload
      }
    },

    // Visual feedback for dragging over the drop zone
    dragOver(event) {
      event.currentTarget.classList.add("bg-grey/10", "border-darkblue");
    },

    // Visual feedback when leaving the drop zone
    dragLeave(event) {
      event.currentTarget.classList.remove("bg-grey/10", "border-darkblue");
    },

    // Validate the selected file
    validateFile(file) {
      if (!file) return;

      const maxFileSize = 10 * 1024 * 1024; // 10 MB
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

      if (!allowedTypes.includes(file.type)) {
        this.uploadError = "Only image files are allowed (JPEG, PNG, GIF).";
        this.selectedFile = null;
      } else if (file.size > maxFileSize) {
        this.uploadError = "File size exceeds the 10MB limit.";
        this.selectedFile = null;
      } else {
        this.selectedFile = file;
        this.uploadError = null; // Clear previous errors
      }
    },

    // Upload the selected file to the backend
    async uploadFile() {
      if (!this.selectedFile) return;

      this.isUploading = true;
      this.uploadProgress = 0;
      this.uploadSuccess = false;
      this.uploadError = null;

      const formData = new FormData();
      formData.append("file", this.selectedFile);

      try {
        const response = await apiClient.post("/api/images/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });

        if (response.status === 201) {
          this.uploadSuccess = true;
          const fileId = response.data.fileId; // Get file ID from response
          this.$emit("file-uploaded", fileId); // Emit file ID to parent component
        }
      } catch (error) {
        this.uploadError =
          error.response?.data?.error ||
          "An error occurred during file upload.";
      } finally {
        this.isUploading = false;
      }
    },
  },
};
</script>

<style scoped></style>
