import axios from "axios";
import { createStore } from "vuex";
import apiClient from "@/api";

// Function to retrieve the stored user from localStorage
function getStoredUser() {
  try {
    const user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.error("Error parsing stored user:", error);
    return null;
  }
}

// Retrieve stored user and token from localStorage
const storedUser = getStoredUser();
const storedToken = localStorage.getItem("token") || null;

// Vuex store definition
const store = createStore({
  state: {
    isAuthenticated: !!storedUser,
    user: storedUser,
    token: storedToken,
  },
  mutations: {
    // Mutation to set user and token in state
    setUser(state, { user, token }) {
      state.user = user;
      state.isAuthenticated = true;
      state.token = token;
      console.log("User  set in Vuex:", user);

      // Store user and token in localStorage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      // Set axios default header for authorization
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    // Separate mutation for updating user data in state
    updateUser(state, user) {
      state.user = user;

      // Update user data in localStorage
      localStorage.setItem("user", JSON.stringify(user));
    },
    // Mutation to clear user data from state and localStorage
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;

      // Remove user and token from localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");

      // Remove authorization headers from axios and apiClient
      delete axios.defaults.headers.common["Authorization"];
      delete apiClient.defaults.headers.common["Authorization"];
    },
    // New mutation to set user data directly after fetching
    SET_USER(state, user) {
      state.user = user;
      // Update user data in localStorage
      localStorage.setItem("user", JSON.stringify(user));
    },
  },
  actions: {
    // Action to handle user login
    async login({ commit }, { email, password }) {
      try {
        // Make the login request to the backend
        const response = await axios.post("/api/users/login", {
          email,
          password,
        });

        // Commit user and token to state
        commit("setUser", {
          user: response.data.user,
          token: response.data.token,
        });

        // Set authorization header after login succeeds
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
      } catch (error) {
        // Handle 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          throw new Error("Invalid email or password.");
        } else {
          console.error("Error logging in:", error);
          throw new Error("An error occurred during login.");
        }
      }
    },

    // Action to handle user registration
    async register(
      { commit },
      {
        name,
        email,
        telephoneCountryPrefix,
        telephone,
        website,
        country,
        nif,
        logo,
        password,
      }
    ) {
      try {
        const response = await axios.post("/api/users/register", {
          name,
          email,
          telephoneCountryPrefix,
          telephone,
          website,
          country,
          nif,
          logo,
          password,
        });

        commit("setUser", {
          user: response.data.user,
          token: response.data.token,
        });

        // Only set the authorization header here after registration succeeds
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          throw new Error("This email address is already in use.");
        } else {
          console.error("Error registering user:", error);
          throw new Error("An error occurred during registration.");
        }
      }
    },

    // Action to handle user logout
    logout({ commit }) {
      commit("logout");
    },

    // Action to fetch the user data from the API
    async fetchUser({ commit }, userId) {
      try {
        const response = await axios.get(`/api/users/${userId}`);
        // Commit user data using SET_USER mutation
        if (response && response.data) {
          commit("SET_USER", response.data);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    isVerified: (state) => state.user?.isVerified || false,
  },
});

export default store;
